import type { HLocation } from '@reach/router';
import { graphql } from 'gatsby';
import * as React from 'react';

import { Layout } from '../components/layout';
import { PostPreviews } from '../components/post-previews';
import type { TSanityModule } from '../components/sanity-modules';
import { SanityModules } from '../components/sanity-modules';
import { SEO } from '../components/seo';
import { ISanityPostPreview } from '../types';

interface ISeo {
  metaDesc: string;
  metaTitle: string;
  shareDesc: string;
  shareGraphic: {
    asset: {
      url: string;
    };
  };
  shareTitle: string;
}

interface IndexPageProps {
  data: {
    sanityHomePage: {
      modules: TSanityModule[];
      seo: ISeo;
    };
    allSanityPost: {
      nodes: ISanityPostPreview[];
      totalCount: number;
    };
  };
  location: HLocation;
}

function IndexPage({ data, location }: IndexPageProps): JSX.Element {
  const { modules, seo } = data.sanityHomePage;

  const filteredModules = modules.filter(
    (module) => module._type != 'contactForm'
  );

  const contactForm = modules.find((module) => module._type === 'contactForm');

  return (
    <>
      <SEO
        title={seo.metaTitle}
        image={seo.shareGraphic?.asset.url}
        pathname={location.pathname}
      />
      <Layout>
        <SanityModules modules={filteredModules} />

        {/* <PostPreviews
          posts={data.allSanityPost.nodes}
          totalCount={data.allSanityPost.totalCount - 1}
          forHomePage={true}
        /> */}
        {contactForm && <SanityModules modules={[contactForm]} />}
      </Layout>
    </>
  );
}

export const query = graphql`
  query HomePageQuery {
    sanityHomePage(_id: { eq: "homePage" }) {
      modules {
        ... on SanityHero {
          ...SanityHeroParts
        }
        ... on SanityCopyWithImage {
          ...SanityCopyWithImageParts
        }
        ... on SanityCopyWithCTA {
          ...SanityCopyWithCTAParts
        }
        ... on SanityServicesGrid {
          ...SanityServicesGridParts
        }
        ... on SanityFrequentlyAskedQuestions {
          ...SanityFrequentlyAskedQuestionsParts
        }
        ... on SanityContactForm {
          ...SanityContactFormParts
        }
        ... on SanityGoogleMap {
          ...SanityGoogleMapParts
        }
        ... on SanityTileBanners {
          ...SanityTileBannersParts
        }
      }
      seo {
        metaDesc
        metaTitle
        shareDesc
        shareGraphic {
          asset {
            url
          }
        }
        shareTitle
      }
    }
    allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      ...SanityPostParts
      totalCount
    }
  }
`;

export default IndexPage;
